import { ProfileIcon } from '@/components/icons'
import { Input, Textarea } from '@nextui-org/input'
import { button as buttonStyles } from "@nextui-org/theme";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure, Checkbox, Link } from "@nextui-org/react";
import { useForm, Controller } from 'react-hook-form';
import ServerRequest from './ServerRequest';
import { backendUri } from '@/config/config';
import { useRouter } from 'next/navigation';
import { motion } from 'framer-motion';

interface AdditionalModalProps {
    onClose: () => void;
    currentJobData: any;
    formData: any;
}
type MotionProps = {
    animate?: any;
    transition?: any;
    while?: boolean;
};
const AdditionalModal: React.FC<AdditionalModalProps> = ({ onClose, currentJobData, formData }) => {
    const router = useRouter()
    const { handleSubmit, control } = useForm();
    const { isOpen, onOpen, onOpenChange } = useDisclosure();
    const { isOpen: alreadyIsopen, onOpen: alreadyOnopen, onOpenChange: alreadyOnOpenChange } = useDisclosure();
    const bounceAnimation: MotionProps = {
        animate: {
            y: ["100px", "0px"],
            rotate: [0, 360],
        },
        transition: {
            yoyo: Infinity,
            ease: "easeOut",
            duration: 0.4,
        },
        while: true,
    };

    const onSubmit = async (data: any) => {

        if (formData?.profileData?.resumeFileName && formData?.profileData?.resumePublicUrl) {
            let Maindata = new FormData();
            const aAdditionalFieldData = Object.keys(data).map(key => ({ [key]: data[key] }));
            const finalData = { ...formData?.data, jobCode: currentJobData?.jobCode, aAdditionalField: aAdditionalFieldData, resumeFileName: formData?.profileData?.resumeFileName, resumePublicUrl: formData?.profileData?.resumePublicUrl }
            delete finalData.file
            if (currentJobData?.adminId) {
                finalData.adminId = currentJobData.adminId
            }

            for (let key in finalData) {

                if (key === 'aAdditionalField') {
                    Maindata.append(key, JSON.stringify(finalData[key]))
                } else {
                    Maindata.append(key, finalData[key])
                }
            }
            let response = await ServerRequest().postData(`${backendUri}/api/v1/job/application/${currentJobData?._id}`, Maindata, { formData: true, authorization: true }, {})
            if (response.status === 200) {
                onOpen()
            } else if (response.status === 422) {
                alreadyOnopen()
            } else {
                router.push('/')
            }
            if (response.status != 200 && response.status == 401) {
                window.location.pathname = "/login";
            }
        } else {
            let Maindata = new FormData();
            const aAdditionalFieldData = Object.keys(data).map(key => ({ [key]: data[key] }));
            const finalData = { ...formData?.data, aAdditionalField: aAdditionalFieldData, jobCode: currentJobData?.jobCode }
            finalData.file = formData?.data?.file?.[0]
            if (currentJobData?.adminId) {
                finalData.adminId = currentJobData.adminId
            }
            for (let key in finalData) {
                if (key === 'aAdditionalField') {
                    Maindata.append(key, JSON.stringify(finalData[key]))
                } else {
                    Maindata.append(key, finalData[key])
                }
            }

            let response = await ServerRequest().postData(`${backendUri}/api/v1/job/application/${currentJobData?._id}`, Maindata, { formData: true, authorization: true }, {})
            if (response.status === 200) {
                onOpen()
            } else if (response.status === 422) {
                alreadyOnopen()
            } else {
                window.location.pathname = "/login";
            }
            if (response.status != 200 && response.status == 401) {
                window.location.pathname = "/login";
            }
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="w-full flex flex-col items-center justify-center ">
                    <div className='w-full grid grid-cols-1 sm:grid-cols-1 grid-rows-1 gap-x-7 gap-y-5'>
                        {currentJobData?.aJobQuestion?.length && (
                            currentJobData?.aJobQuestion?.map((item: string, index: any) => {
                                return (
                                    <Controller
                                        key={index}
                                        name={item}
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Input
                                                isRequired
                                                type="text"
                                                label={`${item}`}
                                                variant="bordered"
                                                labelPlacement='outside'
                                                placeholder={`Enter ${item}`}
                                                {...field}
                                            />
                                        )}
                                    />
                                )
                            })
                        )}
                    </div>
                    <div className="flex gap-10 mt-3 mb-5 justify-center">
                        <button type="submit" className={buttonStyles({ color: "primary", radius: "full", variant: "shadow" })}>
                            Submit
                        </button>
                        <Button onPress={onClose} className={buttonStyles({ variant: "bordered", radius: "full" })}>
                            close
                        </Button>
                    </div>
                </div>
            </form>
            <Modal
                isOpen={isOpen}
                onOpenChange={onOpenChange}
                size='sm'
                backdrop='blur'
                onClose={() => {
                    onClose()
                }}
                style={{ marginTop: '7rem' }}
            >
                <ModalContent>
                    {() => (
                        <>
                            <ModalHeader className="flex flex-col items-center "> Thank You for applying </ModalHeader>
                            <ModalBody className='flex flex-col items-center'>
                                <div className="thumbsup-icon">
                                    <svg id="stripes" className="stripes" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 187 109">
                                        <path d="M4 35h83a4 4 0 0 1 4 4 4 4 0 0 1-4 4H4a4 4 0 0 1-4-4 4 4 0 0 1 4-4zM100 51h83a4 4 0 0 1 4 4 4 4 0 0 1-4 4h-83a4 4 0 0 1-4-4 4 4 0 0 1 4-4zM20 67h131a4 4 0 0 1 4 4 4 4 0 0 1-4 4H20a4 4 0 0 1-4-4 4 4 0 0 1 4-4zM92 91h51a4 4 0 0 1 4 4 4 4 0 0 1-4 4H92a4 4 0 0 1-4-4 4 4 0 0 1 4-4zM92 19h51a4 4 0 0 1 4 4 4 4 0 0 1-4 4H92a4 4 0 0 1-4-4 4 4 0 0 1 4-4z" />
                                    </svg>

                                    <svg className="stars star1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 187 109">
                                        <path d="M68.1 17.4l-4.3-.6-1.9-3.9c-.2-.3-.5-.5-.9-.5s-.7.2-.9.5l-1.9 3.9-4.3.6c-.4.1-.7.3-.8.7-.1.4 0 .8.3 1l3.1 3-.7 4.3c-.1.4.1.8.4 1 .3.2.7.3 1.1.1l3.9-2 3.9 2c.3.2.7.1 1.1-.1s.5-.6.4-1l-.7-4.3 3.1-3c.3-.3.4-.7.3-1-.5-.3-.8-.6-1.2-.7z" />
                                    </svg>

                                    <svg className="stars star2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 187 109">
                                        <path d="M133.1 8.1l-6.6-1-2.9-6c-.3-.5-.8-.8-1.4-.8s-1.1.3-1.4.8l-2.9 6-6.6 1c-.6.1-1.1.5-1.2 1-.2.6 0 1.2.4 1.6l4.8 4.6-1.1 6.6c-.1.6.1 1.1.6 1.5.5.3 1.1.4 1.6.1l5.9-3.1 5.9 3.1c.5.3 1.1.2 1.6-.1s.7-.9.6-1.5l-1.1-6.6 4.8-4.6c.4-.4.6-1 .4-1.6-.4-.5-.8-.9-1.4-1z" />
                                    </svg>

                                    <svg className="stars star3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 187 109">
                                        <path d="M92.9 97.7l-4.6-.7-2-4.1c-.2-.3-.6-.5-.9-.5a1 1 0 0 0-.9.5l-2 4.1-4.5.7c-.4.1-.7.3-.8.7-.1.4 0 .8.3 1.1l3.3 3.2-.8 4.5c-.1.4.1.8.4 1s.8.3 1.1.1l4-2.1 4 2.1c.4.2.8.2 1.1-.1.3-.2.5-.6.4-1l-.8-4.5 3.3-3.2c.3-.3.4-.7.3-1.1-.2-.4-.5-.7-.9-.7z" />
                                    </svg>


                                    <svg className="stars star4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 187 109">
                                        <path d="M45.8 62l-5.7-.8-2.5-5.1c-.2-.4-.7-.7-1.2-.7s-.9.3-1.2.7l-2.5 5.1-5.6.8c-.5.1-.9.4-1.1.9-.2.5 0 1 .3 1.3l4.1 4-1 5.6c-.1.5.1 1 .5 1.3.4.3.9.3 1.4.1l5.1-2.7 5.1 2.7c.4.2 1 .2 1.4-.1.4-.3.6-.8.5-1.3l-1-5.6 4.1-4c.4-.3.5-.9.3-1.3-.1-.5-.5-.8-1-.9z" />
                                    </svg>

                                    <svg className="stars star5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 187 109">
                                        <path d="M142.9 63.7l-2.8-.4-1.3-2.6c-.1-.2-.3-.3-.6-.3s-.5.1-.6.3l-1.3 2.6-2.8.4c-.2 0-.5.2-.5.4-.1.2 0 .5.2.7l2 2-.5 2.8c0 .2.1.5.3.6.2.1.5.2.7 0l2.5-1.3 2.5 1.3h.7c.2-.1.3-.4.3-.6l-.5-2.8 2-2c.2-.2.2-.4.2-.7 0-.2-.2-.4-.5-.4z" />
                                    </svg>

                                    <svg className="thumbsup" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 187 109">
                                        <path d="M55 66H33c-4.3 0-8.7-1-12.5-2.9l-7.1-3.5c-.5-.3-.9-.8-.9-1.4v-22c0-.4.1-.7.4-1l15.3-18.4v-12A4.7 4.7 0 0 1 35.3.7c5.4 3.1 5.6 11.1 5.6 16.6v7.9h17.3c4.3 0 7.9 3.5 7.9 7.8v.2L63 58.3a8.1 8.1 0 0 1-8 7.7z"
                                            fill="#cecece" transform="translate(58 19)" />
                                        <path d="M14.1 66H1.6C.7 66 0 65.3 0 64.4V29.9c0-.9.7-1.6 1.6-1.6h12.6c.9 0 1.6.7 1.6 1.6v34.6c-.1.8-.8 1.5-1.7 1.5z" fill="#000"
                                            transform="translate(58 19)" />
                                    </svg>
                                </div>
                                <div className='text-center mt-2 mb-2'>We'll reach out with more details</div>
                            </ModalBody>
                        </>
                    )}
                </ModalContent>
            </Modal>

            <Modal
                isOpen={alreadyIsopen}
                onOpenChange={alreadyOnOpenChange}
                size='sm'
                backdrop='blur'
                style={{ background: '#fff', color: '#000', marginTop: '7rem' }}
                onClose={() => {
                    onClose()
                }}
            >
                <ModalContent>
                    {() => (
                        <>
                            <ModalHeader className="flex flex-col items-center -mb-2"> Already applied </ModalHeader>
                            <ModalBody className='flex flex-col items-center mb-1'>
                                <motion.div {...bounceAnimation} >
                                    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="150" height="150" ><path d="M23.16,9.88l-2.16-2.16v-1.72c0-1.65-1.35-3-3-3h-1.72L14.12,.84c-1.17-1.17-3.07-1.17-4.24,0l-2.16,2.16h-1.72c-1.65,0-3,1.35-3,3v1.72L.84,9.88C.28,10.45-.04,11.2-.04,12s.31,1.55,.88,2.12l2.16,2.16v1.72c0,1.65,1.35,3,3,3h1.72l2.16,2.16c.58,.58,1.35,.88,2.12,.88s1.54-.29,2.12-.88l2.16-2.16h1.72c1.65,0,3-1.35,3-3v-1.72l2.16-2.16c.57-.57,.88-1.32,.88-2.12s-.31-1.55-.88-2.12Zm-11.16,8.12c-.55,0-1-.45-1-1s.45-1,1-1,1,.45,1,1-.45,1-1,1Zm1-5c0,.55-.45,1-1,1s-1-.45-1-1V7c0-.55,.45-1,1-1s1,.45,1,1v6Z" /></svg>
                                </motion.div>
                            </ModalBody>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    )
}

export default AdditionalModal;
