import { ProfileIcon } from "@/components/icons";
import { Input, Textarea } from "@nextui-org/input";
import NextLink from "next/link";
import Select from "react-select";
import { button as buttonStyles } from "@nextui-org/theme";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Checkbox,
  Link,
} from "@nextui-org/react";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { Select as NextUiSelect, SelectItem } from "@nextui-org/select";
import ServerRequest from "./ServerRequest";
import { backendUri } from "@/config/config";
import { motion } from "framer-motion";
import { useRouter } from "next/navigation";
import { useMediaQuery } from "react-responsive";
import { StateList } from "@/utils/utils";

interface ApplyModalProps {
  onClose: () => void;
  onPress: () => void;
  currentJobData: any;
  setFormData: any;
}
type MotionProps = {
  animate?: any;
  transition?: any;
  while?: boolean;
};
const ApplyModal: React.FC<ApplyModalProps> = ({
  onClose,
  onPress,
  currentJobData,
  setFormData,
}) => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const {
    isOpen: alreadyIsopen,
    onOpen: alreadyOnopen,
    onOpenChange: alreadyOnOpenChange,
  } = useDisclosure();
  const router = useRouter();
  const isSmallScreen = useMediaQuery({ query: "(max-width: 650px)" });
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({ mode: "all" });

  // const bounceAnimation: MotionProps = {
  //   animate: {
  //     y: ["100px", "0px"],
  //     rotate: [0, 360],
  //   },
  //   transition: {
  //     yoyo: Infinity,
  //     ease: "easeOut",
  //     duration: 0.4,
  //   },
  //   while: true,
  // };

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    setIsMobile(isSmallScreen);
  }, [isSmallScreen]);

  const [profileData, setProfileData] = useState<any>(null);

  // Fetch Profile Data from API
  useEffect(() => {
    (async () => {
      try {
        let response = await ServerRequest().getData(
          `${backendUri}/api/v1/user/profile`,
          true
        );
        if (response.status === 200) {
          const result = await response.json();
          const data = result?.data;
          if (data) {
            setProfileData(data);

            // Update form fields individually
            setValue("fullName", data?.fullName);
            setValue("phone_number", data?.phone_number);
            setValue("location", data?.location);
            setValue("email", data?.email);
            setValue("qualification", data?.qualification);
            setValue("totalExperience", data?.totalExperience);
            setValue("currentCompany", data?.currentCompany);
            setValue("department", data?.department);
            setValue("designation", data?.designation);
            setValue("noticePeriod", data?.noticePeriod);
            setValue("roleDescription", data?.roleDescription);
            setValue("currentCTC", data?.currentCTC);
            setValue("expectedCTC", data?.expectedCTC);
            setValue("skills", data?.skills);
            setValue("file", data?.file);
          }
        }
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    })();
  }, []); // Empty dependency array to fetch only once on component mount

  async function onSubmit(data: Record<string, any>) {
    // console.log(data);
    // If job questions are present, we close the modal and update form data
    if (currentJobData?.aJobQuestion?.length) {
      onClose();
      onPress();
      setFormData({ data, profileData });
    } else {
      let Maindata = new FormData();
      if (currentJobData?.adminId) {
        data.adminId = currentJobData.adminId;
      }

      // Handle the resume data
      // if (profileData?.resumeFileName && profileData?.resumePublicUrl) {
      //   const finalData: any = {
      //     ...data,
      //     jobCode: currentJobData?.jobCode,
      //     resumeFileName: profileData?.resumeFileName, // Existing file name
      //     resumePublicUrl: profileData?.resumePublicUrl, // Existing resume URL
      //   };
      //   delete finalData.file; // Don't need to append the file since we're using the existing resume
      //   for (let key in finalData) {
      //     Maindata.append(key, finalData[key]);
      //   }
      // } else {
      //   // If no existing resume, append the file from the input
      //   const finalData: any = { ...data, jobCode: currentJobData?.jobCode };
      //   finalData.file = data?.file?.[0]; // Use the file from the form input
      //   for (let key in finalData) {
      //     Maindata.append(key, finalData[key]);
      //   }
      // }
      let finalData: any = { ...data, jobCode: currentJobData?.jobCode };

      // If profileData has a resume file and public URL
      if (
        profileData?.resumeFileName &&
        profileData?.resumePublicUrl &&
        data?.file === "undefined"
      ) {
        finalData.resumeFileName = profileData?.resumeFileName; // Existing file name
        finalData.resumePublicUrl = profileData?.resumePublicUrl; // Existing resume URL
        delete finalData.file; // Remove the file from the input if using the existing resume
      } else {
        // If no existing resume, use the file from the form input (if available)
        finalData.file = data?.file?.[0] ?? profileData?.resumeFileName;
      }

      // Append the final data to FormData
      for (let key in finalData) {
        Maindata.append(key, finalData[key]);
      }

      let response = await ServerRequest().postData(
        `${backendUri}/api/v1/job/application/${currentJobData?._id}`,
        Maindata,
        { formData: true, authorization: true },
        {}
      );

      let response1 = await ServerRequest().putData(
        `${backendUri}/api/v1/user/profile`,
        Maindata,
        { formData: true, authorization: true },
        {}
      );

      if (response.status === 200) {
        onOpen(); // Success modal
      } else if (response.status === 422) {
        alreadyOnopen(); // Already applied modal
      } else {
        window.alert("Please login to apply");
      }

      // Handle unauthorized request
      if (response.status === 401) {
        window.alert("Please login to apply");
      }
    }
  }

  // Only render form when profile data is available
  if (!profileData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="w-full flex flex-col items-center justify-center ">
        <form
          className="w-full grid lg:grid-cols-3 sm:grid-cols-2 grid-rows-1 gap-x-7 gap-y-5 mt-5"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Input
            className={`${isMobile && "col-span-2"}`}
            isRequired
            type="text"
            label="Full Name"
            variant="bordered"
            {...register("fullName", {
              required: "This field is required.",
            })}
            labelPlacement="outside"
            placeholder="Enter Full Name"
          />

          <Input
            className={`${isMobile && "col-span-2"}`}
            type="text"
            disabled={true}
            label="Mobile Number"
            labelPlacement="outside"
            {...register("phone_number", {
              required: "This field is required.",
            })}
            variant="bordered"
            placeholder="Enter your Number"
            isRequired
          />
          <div className={`${isMobile && "col-span-2"}`}>
            <Controller
              name="location"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "This field is required",
                },
              }}
              render={({ field: { onChange, value, ref } }) => {
                return (
                  <NextUiSelect
                    ref={ref}
                    variant="bordered"
                    labelPlacement="outside"
                    placeholder="Select Location"
                    label="Location"
                    selectedKeys={value ? [value] : ""}
                    value={value}
                    isRequired
                    onChange={(e: any) => onChange(e.target.value)}
                  >
                    {StateList.map((Years: any) => (
                      <SelectItem key={Years.value} value={Years.value}>
                        {Years.label}
                      </SelectItem>
                    ))}
                  </NextUiSelect>
                );
              }}
            />
          </div>

          <Input
            className={`${isMobile && "col-span-2"}`}
            type="email"
            label="Email"
            variant="bordered"
            {...register("email", {
              required: "This field is required.",
            })}
            labelPlacement="outside"
            placeholder="Enter your email"
            isRequired
          />

          <Input
            className={`${isMobile && "col-span-2"}`}
            type="text"
            label="Qualification"
            variant="bordered"
            {...register("qualification", {
              required: "This field is required.",
            })}
            labelPlacement="outside"
            placeholder="Enter your Qualification"
            isRequired
          />

          <Input
            className={`${isMobile && "col-span-2"}`}
            type="number"
            label="Total experience (in Yrs)"
            variant="bordered"
            {...register("totalExperience", {
              required: "This field is required.",
            })}
            labelPlacement="outside"
            placeholder="Enter Experience in years"
            isRequired
          />

          <Input
            className={`${isMobile && "col-span-2"}`}
            type="text"
            label="Current Company"
            variant="bordered"
            {...register("currentCompany", {
              required: "This field is required.",
            })}
            labelPlacement="outside"
            placeholder="Enter Current Company"
            isRequired
          />

          <Input
            className={`${isMobile && "col-span-2"}`}
            type="text"
            label="Department"
            variant="bordered"
            {...register("department", {
              required: "This field is required.",
            })}
            labelPlacement="outside"
            isRequired
            placeholder="Enter Department"
          />

          <Input
            className={`${isMobile && "col-span-2"}`}
            type="text"
            label="Designation"
            variant="bordered"
            {...register("designation", {
              required: "This field is required.",
            })}
            labelPlacement="outside"
            placeholder="Enter your Designation"
            isRequired
          />
          <Input
            className={`${isMobile && "col-span-2"}`}
            type="number"
            labelPlacement="outside"
            label="Notice Period"
            variant="bordered"
            {...register("noticePeriod", {
              required: "This field is required.",
            })}
            placeholder="Enter Notice Period in days"
            isRequired
          />
          <Textarea
            className="col-span-2"
            label="Current Role (Describe Briefly)"
            variant="bordered"
            {...register("roleDescription", {
              required: "This field is required.",
            })}
            labelPlacement="outside"
            placeholder="Enter your experience description"
            isRequired
          />

          <Input
            className={`${isMobile && "col-span-2"}`}
            type="number"
            labelPlacement="outside"
            label="Current CTC"
            variant="bordered"
            {...register("currentCTC", {
              required: "This field is required.",
            })}
            placeholder="Enter Current CTC (in INR lacs)"
            isRequired
          />

          <Input
            className={`${isMobile && "col-span-2"}`}
            type="number"
            labelPlacement="outside"
            label="Expected CTC "
            variant="bordered"
            {...register("expectedCTC", {
              required: "This field is required.",
            })}
            placeholder="Enter Expected CTC (in INR lacs)"
            isRequired
          />

          <Textarea
            className={`${isMobile && "col-span-2"}`}
            label="Skills"
            variant="bordered"
            {...register("skills", {
              required: "This field is required.",
            })}
            labelPlacement="outside"
            placeholder="Enter skills using comma"
            isRequired
          />
          {/* <div className="flex flex-col">
            {profileData?.resumePublicUrl && (
              <>
                <a
                  className={`mt-5 mb-3 ${buttonStyles({
                    color: "default",
                    radius: "full",
                    variant: "shadow",
                    size: "sm",
                  })}`}
                  href={profileData?.resumePublicUrl}
                >
                  My Resume
                </a>
              </>
            )}
            <Input
              className={`${isMobile && "col-span-2"}`}
              type="File"
              labelPlacement="outside-left"
              label="Resume"
              variant="bordered"
              multiple={false}
              {...register("file", {})}
              isRequired={profileData?.resumeFileName ? false : true}
              placeholder="Enter Current CTC (in INR lacs)"
            />
          </div> */}

          <div className="flex flex-col">
            {/* If resumePublicUrl exists, show the resume link */}
            {profileData?.resumePublicUrl && (
              <>
                <a
                  className={`mt-5 mb-3 ${buttonStyles({
                    color: "default",
                    radius: "full",
                    variant: "shadow",
                    size: "sm",
                  })}`}
                  href={profileData?.resumePublicUrl} // Link to the existing resume
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View My Resume
                </a>
              </>
            )}

            {/* Resume upload field */}
            <Input
              className={`${isMobile && "col-span-2"}`}
              type="file"
              labelPlacement="outside-left"
              label="Resume"
              variant="bordered"
              multiple={false}
              {...register("file")}
              isRequired={profileData?.resumeFileName ? false : true} // Only require file if there isn't an existing resume
              placeholder="Upload Resume"
            />
          </div>

          <div
            className={`flex gap-10 mt-3 mb-5 justify-center ${
              isMobile && "col-span-2"
            }`}
          >
            <button
              className={buttonStyles({
                color: "primary",
                radius: "full",
                variant: "shadow",
              })}
              type="submit"
            >
              Submit
            </button>

            <button
              className={buttonStyles({ variant: "bordered", radius: "full" })}
              type="button"
              onClick={() => onClose()}
            >
              close
            </button>
          </div>
        </form>
      </div>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        size="sm"
        backdrop="blur"
        onClose={() => {
          onClose();
        }}
      >
        <ModalContent>
          {() => (
            <>
              <ModalHeader className="flex flex-col items-center ">
                {" "}
                Thank You for applying{" "}
              </ModalHeader>
              <ModalBody className="flex flex-col items-center">
                <div className="thumbsup-icon">
                  <svg
                    id="stripes"
                    className="stripes"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 187 109"
                  >
                    <path d="M4 35h83a4 4 0 0 1 4 4 4 4 0 0 1-4 4H4a4 4 0 0 1-4-4 4 4 0 0 1 4-4zM100 51h83a4 4 0 0 1 4 4 4 4 0 0 1-4 4h-83a4 4 0 0 1-4-4 4 4 0 0 1 4-4zM20 67h131a4 4 0 0 1 4 4 4 4 0 0 1-4 4H20a4 4 0 0 1-4-4 4 4 0 0 1 4-4zM92 91h51a4 4 0 0 1 4 4 4 4 0 0 1-4 4H92a4 4 0 0 1-4-4 4 4 0 0 1 4-4zM92 19h51a4 4 0 0 1 4 4 4 4 0 0 1-4 4H92a4 4 0 0 1-4-4 4 4 0 0 1 4-4z" />
                  </svg>

                  <svg
                    className="stars star1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 187 109"
                  >
                    <path d="M68.1 17.4l-4.3-.6-1.9-3.9c-.2-.3-.5-.5-.9-.5s-.7.2-.9.5l-1.9 3.9-4.3.6c-.4.1-.7.3-.8.7-.1.4 0 .8.3 1l3.1 3-.7 4.3c-.1.4.1.8.4 1 .3.2.7.3 1.1.1l3.9-2 3.9 2c.3.2.7.1 1.1-.1s.5-.6.4-1l-.7-4.3 3.1-3c.3-.3.4-.7.3-1-.5-.3-.8-.6-1.2-.7z" />
                  </svg>

                  <svg
                    className="stars star2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 187 109"
                  >
                    <path d="M133.1 8.1l-6.6-1-2.9-6c-.3-.5-.8-.8-1.4-.8s-1.1.3-1.4.8l-2.9 6-6.6 1c-.6.1-1.1.5-1.2 1-.2.6 0 1.2.4 1.6l4.8 4.6-1.1 6.6c-.1.6.1 1.1.6 1.5.5.3 1.1.4 1.6.1l5.9-3.1 5.9 3.1c.5.3 1.1.2 1.6-.1s.7-.9.6-1.5l-1.1-6.6 4.8-4.6c.4-.4.6-1 .4-1.6-.4-.5-.8-.9-1.4-1z" />
                  </svg>

                  <svg
                    className="stars star3"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 187 109"
                  >
                    <path d="M92.9 97.7l-4.6-.7-2-4.1c-.2-.3-.6-.5-.9-.5a1 1 0 0 0-.9.5l-2 4.1-4.5.7c-.4.1-.7.3-.8.7-.1.4 0 .8.3 1.1l3.3 3.2-.8 4.5c-.1.4.1.8.4 1s.8.3 1.1.1l4-2.1 4 2.1c.4.2.8.2 1.1-.1.3-.2.5-.6.4-1l-.8-4.5 3.3-3.2c.3-.3.4-.7.3-1.1-.2-.4-.5-.7-.9-.7z" />
                  </svg>

                  <svg
                    className="stars star4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 187 109"
                  >
                    <path d="M45.8 62l-5.7-.8-2.5-5.1c-.2-.4-.7-.7-1.2-.7s-.9.3-1.2.7l-2.5 5.1-5.6.8c-.5.1-.9.4-1.1.9-.2.5 0 1 .3 1.3l4.1 4-1 5.6c-.1.5.1 1 .5 1.3.4.3.9.3 1.4.1l5.1-2.7 5.1 2.7c.4.2 1 .2 1.4-.1.4-.3.6-.8.5-1.3l-1-5.6 4.1-4c.4-.3.5-.9.3-1.3-.1-.5-.5-.8-1-.9z" />
                  </svg>

                  <svg
                    className="stars star5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 187 109"
                  >
                    <path d="M142.9 63.7l-2.8-.4-1.3-2.6c-.1-.2-.3-.3-.6-.3s-.5.1-.6.3l-1.3 2.6-2.8.4c-.2 0-.5.2-.5.4-.1.2 0 .5.2.7l2 2-.5 2.8c0 .2.1.5.3.6.2.1.5.2.7 0l2.5-1.3 2.5 1.3h.7c.2-.1.3-.4.3-.6l-.5-2.8 2-2c.2-.2.2-.4.2-.7 0-.2-.2-.4-.5-.4z" />
                  </svg>

                  <svg
                    className="thumbsup"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 187 109"
                  >
                    <path
                      d="M55 66H33c-4.3 0-8.7-1-12.5-2.9l-7.1-3.5c-.5-.3-.9-.8-.9-1.4v-22c0-.4.1-.7.4-1l15.3-18.4v-12A4.7 4.7 0 0 1 35.3.7c5.4 3.1 5.6 11.1 5.6 16.6v7.9h17.3c4.3 0 7.9 3.5 7.9 7.8v.2L63 58.3a8.1 8.1 0 0 1-8 7.7z"
                      fill="#cecece"
                      transform="translate(58 19)"
                    />
                    <path
                      d="M14.1 66H1.6C.7 66 0 65.3 0 64.4V29.9c0-.9.7-1.6 1.6-1.6h12.6c.9 0 1.6.7 1.6 1.6v34.6c-.1.8-.8 1.5-1.7 1.5z"
                      fill="#000"
                      transform="translate(58 19)"
                    />
                  </svg>
                </div>
                <div className="text-center mt-2 mb-2">
                  We'll reach out with more details
                </div>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>

      <Modal
        isOpen={alreadyIsopen}
        onOpenChange={alreadyOnOpenChange}
        size="sm"
        backdrop="blur"
        style={{ background: "#fff", color: "#000" }}
        onClose={() => {
          onClose();
        }}
      >
        <ModalContent>
          {() => (
            <>
              <ModalHeader className="flex flex-col items-center -mb-2">
                {" "}
                Already applied{" "}
              </ModalHeader>
              <ModalBody className="flex flex-col items-center mb-1">
                {/* <motion.div {...bounceAnimation}> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="Layer_1"
                  data-name="Layer 1"
                  viewBox="0 0 24 24"
                  width="150"
                  height="150"
                >
                  <path d="M23.16,9.88l-2.16-2.16v-1.72c0-1.65-1.35-3-3-3h-1.72L14.12,.84c-1.17-1.17-3.07-1.17-4.24,0l-2.16,2.16h-1.72c-1.65,0-3,1.35-3,3v1.72L.84,9.88C.28,10.45-.04,11.2-.04,12s.31,1.55,.88,2.12l2.16,2.16v1.72c0,1.65,1.35,3,3,3h1.72l2.16,2.16c.58,.58,1.35,.88,2.12,.88s1.54-.29,2.12-.88l2.16-2.16h1.72c1.65,0,3-1.35,3-3v-1.72l2.16-2.16c.57-.57,.88-1.32,.88-2.12s-.31-1.55-.88-2.12Zm-11.16,8.12c-.55,0-1-.45-1-1s.45-1,1-1,1,.45,1,1-.45,1-1,1Zm1-5c0,.55-.45,1-1,1s-1-.45-1-1V7c0-.55,.45-1,1-1s1,.45,1,1v6Z" />
                </svg>
                {/* </motion.div> */}
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default ApplyModal;
