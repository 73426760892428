import React, { Fragment, useState, useEffect } from 'react';
import {
    LinkedinShareButton,
    WhatsappShareButton,
    LinkedinIcon,
    WhatsappIcon,
} from 'react-share';
import Cookies from 'js-cookie';
import ServerRequest from './ServerRequest';
import { backendUri } from '@/config/config';


const Share: React.FC<any> = (props) => {
    const [state, setState] = useState({
        jobId: props.id,
        whatsAppUrl: '',
        linkedInUrl: '',
        randomId: Math.floor(Math.random() * 1000),
        copyUrl: '',
        copyText: '',
    });
    useEffect(() => {
        (async () => {
            let token = Cookies.get('cat');
            let reqBody = {
                channel: 'whatsapp',
                jobId: props.id,
            };

            try {
                let response = await ServerRequest().postData(
                    `${backendUri}/api/v1/share/generate`,
                    JSON.stringify(reqBody),
                    { formData: false, authorization: true },
                    {}
                );

                if (response.status === 200) {
                    let result = await response.json();
                    setState((prevState) => ({
                        ...prevState,
                        whatsAppUrl: result.data.url,
                    }));
                } else {
                    window.location.pathname = '/login';
                }

                reqBody.channel = 'linkedIn';
                let response2 = await ServerRequest().postData(
                    `${backendUri}/api/v1/share/generate`,
                    JSON.stringify(reqBody),
                    { formData: false, authorization: true },
                    {}
                );

                if (response2.status === 200) {
                    let result = await response2.json();
                    setState((prevState) => ({
                        ...prevState,
                        linkedInUrl: result.data.url,
                    }));
                } else {
                    window.location.pathname = '/login';
                }

                reqBody.channel = 'Copy';
                let response3 = await ServerRequest().postData(
                    `${backendUri}/api/v1/share/generate`,
                    JSON.stringify(reqBody),
                    { formData: false, authorization: true },
                    {}
                );

                if (response3.status === 200) {
                    let result = await response3.json();
                    setState((prevState) => ({
                        ...prevState,
                        copyUrl: result.data.url,
                    }));
                    // console.log('result.data.url', result.data.url)
                } else {
                    window.location.pathname = '/login';
                }
            } catch (error) {
                console.error('Error:', error);
            }
        })();
    }, [props.id]);

    const copyToClip = async () => {
        try {
            await navigator.clipboard.writeText(state.copyUrl);
            setState((prevState) => ({ ...prevState, copyText: 'Copied' }));
        } catch (error) {
            console.error('Error copying to clipboard:', error);
        }
    };

    return (
        <div className='flex gap-2 items-center' >
            <LinkedinShareButton url={state.linkedInUrl}>
                <LinkedinIcon size={32} round={true} />
            </LinkedinShareButton>
            <WhatsappShareButton
                title="Hi, Check out this job. I think you are a good fit for this opportunity – "
                separator=" - "
                url={state.whatsAppUrl}
            >
                <WhatsappIcon size={32} round={true} />
            </WhatsappShareButton>
            <img
                src="/copy.png"
                onClick={copyToClip}
                style={{
                    height: '25px',
                    width: '25px',
                    cursor: 'pointer',
                }}
            />
            <span>{state.copyText}</span>
        </div>
    );
};

export default Share;
