/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from 'react';
import Cookies from 'js-cookie';





export default function ServerRequest () {

    async function getData (uri: string, authorization: boolean) {
        const headers: any = getHeaders({ formData: false, authorization });
        return await fetch(`${uri}`, { headers });
    }

    const getToken = () => {
        let token = Cookies.get('cat');
        if (!token || !token.length) {

        }
        return token;
    };

    const getHeaders = (options: any) => {
        let headers;
        if (options.formData) {
            headers = {
                'Access-Control-Allow-Origin': '*',
                'Authorization': options.authorization ? `Bearer ${getToken()}` : null
            };
        } else {
            headers = {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Authorization': options.authorization ? `Bearer ${getToken()}` : null
            };
        }
        return headers;
    };

    const postData = async (uri: any, body: any, options: any, optionalHeaders: any) => {
        return await fetch(`${uri}`, {
            method: 'POST',
            headers: optionalHeaders ? { ...getHeaders(options), ...optionalHeaders } : getHeaders(options),
            body: body
        });
    };

    const putData = async (uri: any, body: any, options: any, optionalHeaders: any) => {
        return await fetch(`${uri}`, {
            method: 'PUT',
            headers: optionalHeaders ? { ...getHeaders(options), ...optionalHeaders } : getHeaders(options),
            body: body
        });
    };

    const deleteData = async (uri: any, authorization: boolean) => {
        const headers: any = {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
        };;
        return await fetch(`${uri}`, {
            method: 'DELETE',
            headers: headers,
        });
    };

    return {
        getData,
        postData,
        putData,
        deleteData
    };
}
